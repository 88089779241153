import {
  PreparationStep,
  Preparation,
  Handover,
  HandoverTableData,
  HandoverState,
  Note,
  ExtraPackage
} from '~app/apollo/queries/getHandoverExtended';
import { OrderlineStatus } from '~app/apollo/queries/getOrder';
import { isNonEmptyString, isPositiveNumber } from '~app/helpers/stringHelper';
import { convertOrder } from '~app/helpers/handoverConverter';
import { isFinanceTypeAnyLoan, isStatusPreOrdered } from '~app/helpers/orderHelper';
import { VehicleState } from '../contexts/Moment/context';
import { DocumentType } from '~app/hooks/useDocuments';
import {
  ExtrasOrdered,
  NoteType,
  ExtraProductTypes,
  PreparationOptionalSteps,
  HandoverMethods,
  HandoverPreparationStatuses,
  VehiclePreparationStatuses
} from '~app/enums';
import { Path } from '~root/src/routes/definition';
import { DealerLocation } from '~apollo/queries/getLocation';
import { MarketIds } from './constants';

export const updatePreparation = (
  preparations: Array<Preparation>,
  userId: string,
  userName: string,
  step: PreparationStep,
  add: boolean,
  date?: string
): Array<Preparation> => {
  const preps = [
    ...preparations
      .filter(x => x.preparationId !== step)
      .map(x => ({
        date: x.date,
        preparationId: x.preparationId,
        userId: x.userId,
        userName: x.userName
      }))
  ];
  if (add) {
    preps.push({
      date: date ?? new Date().toISOString(),
      preparationId: step,
      userId: userId,
      userName: userName
    });
  }

  return preps;
};

export const getAllNotesAndAppendNew = (
  handover: Handover,
  reasonForCancelling: string,
  creationDate: string,
  title: string,
  name: string
) => {
  const updatedNotes = (handover.notes as Array<Note & { __typename?: string }>).map(note => {
    const n = { ...note };
    delete n['__typename'];
    return n;
  });

  updatedNotes?.push({
    id: (updatedNotes.length + 1).toString() + `_title_${creationDate}`,
    type: NoteType.BOOKING_CANCELLATION,
    title: title,
    text: reasonForCancelling,
    created: creationDate,
    addedBy: name
  });

  return updatedNotes;
};

export const updatePreparationCollection = (
  handoverPreparations: Array<Preparation>,
  userId: string,
  userName: string,
  allEquipmentNames: Array<string>,
  checkedEquipmentNames: Array<{ name: string; file?: File; subType: string }>,
  formatPreparationId: (value: string) => string
) => {
  // Deep copy to get rid of __typename and reference
  let preparations: Array<Preparation> = handoverPreparations.map(x => ({
    preparationId: x.preparationId,
    date: x.date,
    userId: x.userId,
    userName: x.userName
  }));
  allEquipmentNames.forEach((x: string) => {
    preparations = updatePreparation(
      preparations,
      userId,
      userName,
      formatPreparationId(x),
      checkedEquipmentNames.some(e => e.name === x)
    );
  });
  return preparations;
};

export const getSelectedExtraEquipment = (preparations: Array<Preparation> | undefined | null) => {
  if (preparations == null) {
    return [];
  }
  return preparations
    .filter(x => x.preparationId.startsWith('xequip_'))
    .map(x => x.preparationId.split('xequip_')[1]);
};

export const isOrderConfirmed = (preparations: Array<Preparation> | undefined | null) => {
  return (
    preparations?.find(x => x.preparationId === PreparationOptionalSteps.ORDER_CONFIRMED) !==
    undefined
  );
};

export const isWheelsConfirmed = (preparations: Array<Preparation> | undefined | null) => {
  return (
    preparations?.find(x => x.preparationId === PreparationOptionalSteps.WHEELS_OK) !== undefined
  );
};

export const isRankMarkAdded = (preparations: Array<Preparation> | undefined | null) => {
  return (
    preparations?.find(x => x.preparationId === PreparationOptionalSteps.RANK_MARK) !== undefined
  );
};

export const isMUAUnsignedConfirmed = (preparations: Array<Preparation> | undefined | null) => {
  return (
    preparations?.find(x => x.preparationId === PreparationOptionalSteps.MUA_UNSIGNED) !== undefined
  );
};

export const isThirdPartyDeliveryDocChecked = (
  preparations: Array<Preparation> | undefined | null
) => {
  return (
    preparations?.find(
      x => x.preparationId === PreparationOptionalSteps.THIRD_PARTY_DELIVERY_ACCEPTANCE
    ) !== undefined
  );
};

export const filterFaultyFormatVins = (vinNbrs: string): [Set<string>, Set<string>] => {
  const vins = vinNbrs.split(';');
  const correctVins = new Set<string>();
  const incorrectVins = new Set<string>();
  vins.forEach(vin => {
    const trimmedVin = vin.trim().toUpperCase();
    if (trimmedVin.length > 0) {
      trimmedVin.length === 17 ? correctVins.add(trimmedVin) : incorrectVins.add(trimmedVin);
    }
  });
  return [correctVins, incorrectVins];
};

export const filterFaultyFormatVinsWithRegex = (
  vinNbrs: string,
  regex = /[^a-zA-Z0-9 :]/g
): [Set<string>, Set<string>] => {
  const vins = vinNbrs.split(regex);
  const correctVins = new Set<string>();
  const incorrectVins = new Set<string>();
  vins.forEach(vin => {
    const trimmedVin = vin.trim().toUpperCase();
    if (trimmedVin.length > 0) {
      trimmedVin.length === 17 ? correctVins.add(trimmedVin) : incorrectVins.add(trimmedVin);
    }
  });
  return [correctVins, incorrectVins];
};

export const filterNonExistingVins = (
  handovers: Array<Handover>,
  vinsUpperCase: Set<string>
): [Array<Handover>, Set<string>] => {
  const correctVins: Array<Handover> = [];
  const incorrectVins = new Set<string>();
  vinsUpperCase.forEach(vin => {
    const found = handovers.find(x => x.vin?.toUpperCase() === vin);
    if (found !== undefined) {
      correctVins.push(found);
    } else {
      incorrectVins.add(vin);
    }
  });
  return [correctVins, incorrectVins];
};

export const towbarPreparationStep = (ordernumber: string) => `extra_A00463_${ordernumber}`;

export const isTowbarOrdered = (handover: HandoverTableData) => {
  return (
    // Phase one
    handover.preparations?.some(
      x => x.preparationId === towbarPreparationStep(handover.orderNumber)
    ) ||
    // Phase two
    !isStatusPreOrdered(
      handover.extraPackages?.find(x => x.productType === 'Towbar')?.orderlineStatus ?? 'confirmed'
    )
  );
};

const filterWinterWheels = (
  handover: HandoverTableData,
  extras: Array<ExtraPackage>
): Array<ExtraPackage> => {
  //!needs_winter_wheel_mounting && !wants_together_with_car => we dont care about this extra being completed
  const needsWinterWheelMount = handover.orderData?.needsWinterWheelMounting;
  const wantsTogetherWithCar = handover.extraPackages?.find(
    x => x.productType === ExtraProductTypes.WINTER_WHEELS
  )?.winterTireWantsTogetherWithCar;

  if (
    !needsWinterWheelMount &&
    !wantsTogetherWithCar &&
    wantsTogetherWithCar !== null &&
    wantsTogetherWithCar !== undefined
  ) {
    extras = extras.filter(x => x.productType !== ExtraProductTypes.WINTER_WHEELS);
  }
  return extras;
};

const getValidPomsExtras = (handover: HandoverTableData): Array<ExtraPackage> => {
  return (
    handover.extraPackages?.filter(
      x =>
        x.orderlineStatus !== 'cancelled' &&
        x.orderlineStatus !== 'returned' &&
        x.productType !== 'Towbar'
    ) ?? []
  );
};

export const extrasOrderStatus = (handover: HandoverTableData) => {
  let allValidPOMSExtras = getValidPomsExtras(handover);
  allValidPOMSExtras = filterWinterWheels(handover, allValidPOMSExtras);

  let totalExtrasToOrder = allValidPOMSExtras.length;
  let totalOrdered = allValidPOMSExtras.filter(
    x => !isStatusPreOrdered(x.orderlineStatus ?? 'pending')
  ).length;

  let totalCompleted = allValidPOMSExtras.filter(
    x => x.orderlineStatus === 'ready_for_delivery' || x.orderlineStatus === 'delivered'
  ).length;

  //Needed since tow bar is filtered out in the beginning of method since it is special
  if (handover.extraPackages?.some(x => x.productType === 'Towbar')) {
    totalExtrasToOrder += 1;
    if (isTowbarOrdered(handover)) {
      totalOrdered++;
      const towbar = handover.extraPackages?.find(x => x.productType === 'Towbar');
      if (
        towbar?.orderlineStatus === 'ready_for_delivery' ||
        towbar?.orderlineStatus === 'delivered'
      )
        totalCompleted++;
    }
  }

  if (totalExtrasToOrder === 0) {
    return ExtrasOrdered.NOTHING_TO_ORDER;
  } else if (totalOrdered === 0) {
    return ExtrasOrdered.NOT_ORDERED;
  } else if (totalCompleted === totalExtrasToOrder) {
    return ExtrasOrdered.ALL_COMPLETED;
  } else if (totalExtrasToOrder > totalOrdered) {
    return ExtrasOrdered.PARTLY_ORDERED;
  } else {
    return ExtrasOrdered.ALL_ORDERED;
  }
};

export const canStartHandover = (handover: Handover) => {
  if (
    handover.documents?.find(x => x.documentType === 'DeliveryAcceptance')?.signingStatus ===
    'SIGNING_INITIATED'
  ) {
    return true;
  }
  return (
    isOrderConfirmed(handover.preparations) &&
    handover.handoverPreparationState === HandoverPreparationStatuses.COMPLETED &&
    (handover.vehiclePreparationState === VehiclePreparationStatuses.COMPLETED ||
      handover.vehiclePreparationState === VehiclePreparationStatuses.NOT_APPLICABLE)
  );
};

export const canUpdateHandover = (
  handoverState: HandoverState,
  vehicleOrderlineStatus: OrderlineStatus | null
) => {
  return (
    (handoverState !== 'DONE' &&
      handoverState !== 'CANCELLED' &&
      handoverState !== 'PARTIALLY_DELIVERED') ||
    (handoverState === 'PARTIALLY_DELIVERED' && vehicleOrderlineStatus !== 'delivered')
  );
};

export const getImageFileExtensionFromFile = (file: File) => {
  return file.type === 'image/png' ? '.png' : file.type === 'image/jpg' ? '.jpg' : '.jpeg';
};

export const getDocumentTypesAndExtension = (area: VehicleState) => {
  let documentMainType: DocumentType;
  let documentSubType;
  if (area.area === 'Interior') {
    documentMainType = 'PhotoInterior';
    if (area.value === 'InteriorFrontCab') {
      documentSubType = 'FrontCab';
    } else if (area.value === 'InteriorCenterConsole') {
      documentSubType = 'CenterConsole';
    } else if (area.value === 'InteriorRearCab') {
      documentSubType = 'RearCab';
    } else {
      documentSubType = 'Boot';
    }
  } else {
    documentMainType = 'PhotoExterior';
    documentSubType = area.value.replace('Exterior', '');
  }
  let extension = '.jpeg';
  if (area.uploadedFile) {
    extension = getImageFileExtensionFromFile(area.uploadedFile);
  }

  return { mainType: documentMainType, subType: documentSubType, extension: extension };
};

export const isFinancingInProgress = (handoverOrder: ReturnType<typeof convertOrder>) => {
  return (
    (isFinanceTypeAnyLoan(handoverOrder.financeType) || handoverOrder.financeType === 'Leasing') &&
    !handoverOrder.isOtherPaymentSigned
  );
};

export const showRegistrationReminder = (handover: Handover) =>
  !(
    isNonEmptyString(handover.orderData?.registrationDate) &&
    isNonEmptyString(handover.orderData?.vehicleRegistrationNumber)
  ) && handover.documents?.some(doc => doc.documentType === 'AuthorisationForm');

export const needsNOFinancing = (handoverOrder: ReturnType<typeof convertOrder>) =>
  handoverOrder.market === 'NO' && isFinancingInProgress(handoverOrder);

export const needsBEFinancing = (handoverOrder: ReturnType<typeof convertOrder>) =>
  handoverOrder.market === 'BE' &&
  handoverOrder.personalFinancingStatus &&
  handoverOrder.personalFinancingStatus !== 'approved' &&
  (handoverOrder.financeType === 'Personal Loan' ||
    handoverOrder.financeType === 'Personal Leasing');

export const hasAuthFormFlow = (
  handover: Handover,
  handoverOrder: ReturnType<typeof convertOrder>
) => {
  const isAuthFormSigned = isNonEmptyString(
    handover.documents?.find(x => x.documentType === 'AuthorisationForm')?.documentId
  );
  return (
    handover.marketId?.toLowerCase() === 'nl' &&
    (handoverOrder.financeType === 'Cash' ||
      isAuthFormSigned ||
      (handoverOrder.financeType === 'Personal Leasing' &&
        handoverOrder.fspRegistrationData?.orgnumber === '30046259'))
  );
};

export const isBulkOrder = (handover?: Handover) =>
  handover?.orderData?.handoverMethod === HandoverMethods.BULK_COLLECT ||
  handover?.orderData?.handoverMethod === HandoverMethods.BULK_CUSTOMER_DELIVERY;

export const getOriginLocationFromHandover = (handover: Handover) => {
  let originLocation = { pathname: Path.ORDERS as string, search: '', hash: '' };
  if (isBulkOrder(handover)) {
    if (isNonEmptyString(handover.batch?.id))
      originLocation.pathname = '/batch/' + handover.batch?.id;
    else {
      const orgnumber = handover.orderData?.roleHolders?.find(holder =>
        holder.roles.includes('owner')
      )?.organizationNumber;
      originLocation.pathname = `/bulk-orders/${isNonEmptyString(orgnumber) ? orgnumber : ''}`;
    }
  }

  return originLocation;
};

export const showRegistrationReportReminder = (handover: Handover, userLocation: DealerLocation) =>
  userLocation.marketId === MarketIds.netherland &&
  !handover.documents?.find(doc => doc.documentType === 'RegistrationReport');

export const isSOCValid = (soc: string) => {
  return isNonEmptyString(soc) && +soc >= 0 && +soc <= 100;
};

export const isOdometerValid = (odometer: string) => {
  return isNonEmptyString(odometer) && isPositiveNumber(odometer);
};
